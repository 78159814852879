import React, { Component, Suspense } from 'react';
import axios from 'axios';

import Thanks from '../Thanks';
import Input, { Checkbox, Textarea } from '../forms/input';
const VideoELement = React.lazy(() => import('./VideoElement'));

class Pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formdata: {},
      showThanks: false,
      loadedThanks: false,
      step: props.step,
      showOther: false,
      platforms: props.platform ? [props.platform] : [],
      smmPackages: [],
      disabled: true,
      req: false,
      videoLength: [],
      script: [],
      loaded: false,
      loading: false,
    };
    this.stepOne = React.createRef();
    this.stepTwo = React.createRef();
    this.stepTree = React.createRef();
    this.mainRef = React.createRef();

    this.handleChange = this.handleChange.bind(this);
    this.hideThanksModal = this.hideThanksModal.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.show) {
      this.setState({ loaded: true });
    }

    if (nextProps.platforms != this.state.platforms)
      this.setState({
        platforms: nextProps.platform ? [nextProps.platform] : [],
      });
  }

  handleChange(param, value) {
    this.setState({ loading: false });
    if (param == 'smmPackage') {
      if (document.getElementById('other_ch').checked) this.setState({ showOther: true });
      else this.setState({ showOther: false });

      let checkboxes = document.getElementsByName('smmPackage');
      let checkboxesChecked = [];

      for (let i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].checked) {
          checkboxesChecked.push(checkboxes[i].value);
        }
      }
      if (document.getElementById('other_ch').checked) {
        checkboxesChecked.push(document.getElementById('other').value);
      }
      if (checkboxesChecked.length >= 1) {
        this.setState({ smmPackages: checkboxesChecked });
      } else {
        this.setState({ smmPackages: [] });
      }
    }

    if (param == 'platform') {
      let checkboxes = document.getElementsByName('platform');
      let checkboxesChecked = [];
      for (let i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].checked) {
          checkboxesChecked.push(checkboxes[i].value);
        }
      }
      if (checkboxesChecked.length >= 1) {
        this.setState({ platforms: checkboxesChecked });
      } else {
        this.setState({ platforms: [] });
      }
    }

    this.setState(prevState => {
      let formdata = Object.assign({}, prevState.formdata);
      formdata[param] = value;
      return { formdata };
    });
  }

  hideThanksModal() {
    this.setState({
      loadedThanks: false,
      showThanks: false,
    });
    document.body.style.overflow = 'auto';

    document.body.style.overflowX = 'hidden';
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState({ loading: true });

    let form_data = new FormData();

    for (let key in this.state.formdata) {
      if (key == 'video-type') {
        form_data.append('video-type', this.props.from == 'healthcare' ? 'Healthcare ' + this.state.formdata[key] : this.state.formdata[key]);
      } else {
        form_data.append(key, Array.isArray(this.state.formdata[key]) ? this.state.formdata[key].join(',') : this.state.formdata[key]);
      }
    }

    if (document.getElementById('other')) form_data.append('other-length', document.getElementById('other').value);
    if (document.getElementById('budget-amount')) form_data.append('budget-amount', document.getElementById('budget-amount').value);
    form_data.append('country', this.props.country);
    form_data.append('video-length', this.state.videoLength.join(' , '));
    form_data.append('script', this.state.script.join(' , '));
    form_data.append('isBusinessEmail', this.isEmailBusiness(this.state.formdata.email));

    document.body.style.overflow = 'hidden';

    axios.post('https://mailer.weprodigi.com/actions/send-business-mail.php', form_data).then(response => {
      if (this.isEmailBusiness(this.state.formdata.email)) window.location.href = 'https://weprodigi.com/thank-you';
    });
  }

  setVideoLength = (name, value) => {
    let arr = [];
    arr.push(value);
    this.setState({ videoLength: arr });
  };

  setScript = (name, value) => {
    let arr = [];
    arr.push(value);
    this.setState({ script: arr });
  };

  hide = () => {
    this.props.hide();
  };

  emailValidation = email => {
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!email || regex.test(email) === false) {
      return false;
    }
    return true;
  };

  isEmailBusiness = email => {
    const publicDomains = [
      'gmail.com',
      'yahoo.com',
      'outlook.com',
      'hotmail.com',
      'aol.com',
      'icloud.com',
      'mail.com',
      'mail.ru',
      'zoho.com',
      'yandex.com',
      'yandex.ru',
      'protonmail.com',
    ];

    const domain = email?.split('@')[1];

    if (publicDomains.includes(domain)) {
      return false;
    }
    return true;
  };

  render() {
    const { step, script, videoLength, loading } = this.state;
    return (
      <>
        <div
          className={
            'fixed z-900 bg-navbar w-full h-full left-0 top-0 right-0 bottom-0 items-center justify-center' +
            ' ' +
            (this.props.show && !this.state.showThanks ? 'flex' : 'hidden')
          }
        >
          <div className="absolute left-0 top-0 w-full h-full" onClick={this.hide} />

          <div className="relative transform w-11/12 max-w-screen-md max-h-screen-md bg-gray border border-gray-transparent rounded-5 md:scale-100">
            <div
              onClick={this.hide}
              className="absolute -right-12 -top-12 bg-purple text-white w-30 h-30 text-21 rounded-full flex items-center justify-center cursor-pointer select-none"
            >
              <span className="font-bold">X</span>
            </div>

            <div className="py-23 px-50 max-h-650  overflow-y-auto">
              <p className="text-purple uppercase text-24 text-center font-black leading-none" ref={this.mainRef}>
                GET PRICING
              </p>

              <p className="text-black text-12 mt-20">
                {this.props.from == 'healthcare'
                  ? 'Kindly answer a few questions in order for us to provide you with healthcare video pricing information. An email address is required in the form.*'
                  : 'Kindly answer a few questions in order for us to provide you with video production pricing information. An email address is required in the form.*'}{' '}
              </p>
              <form id={this.props.id ? 'pricing-form_' + this.props.id : 'pricing-form'} method="POST" onSubmit={e => this.onSubmit(e)}>
                <div ref={this.stepOne} className={`mt-20 ${step == 1 ? 'block' : 'hidden'}`}>
                  <p className={'text-16 flex items-center flex-wrap text-purple font-bolder mt-20'}>
                    {' '}
                    {this.props.from == 'healthcare' ? 'What is your preferred healthcare video style?' : 'What is your preferred video style?'}{' '}
                    {this.state.req && this.state.formdata['video-type'] == undefined && (
                      <span
                        className="bg-green animate-warning text-white text-12 normal-case font-normal px-5 rounded-5"
                        style={{ verticalAlign: 'super' }}
                      >
                        This is required
                      </span>
                    )}{' '}
                  </p>

                  <div className="grid grid-cols-2 md:grid-cols-5 gap-10 md:gap-5 mt-10">
                    {this.state.loaded && (
                      <Suspense fallback={<h2>Still Loading…</h2>}>
                        <VideoELement
                          video="https://weprodigi.com/videos/quote-video/4.mp4"
                          poster="/images/quote-video/posters/4.jpg"
                          title="2D Animation"
                          selected={this.state.formdata['video-type']}
                          name="video-type"
                          onChange={this.handleChange}
                        />

                        <VideoELement
                          video="https://weprodigi.com/videos/quote-video/5.mp4"
                          poster="/images/quote-video/posters/5.jpg"
                          title="2D Character Animation"
                          selected={this.state.formdata['video-type']}
                          name="video-type"
                          onChange={this.handleChange}
                        />

                        <VideoELement
                          video="https://weprodigi.com/videos/quote-video/6.mp4"
                          poster="/images/quote-video/posters/6.jpg"
                          title="3D Animation"
                          name="video-type"
                          selected={this.state.formdata['video-type']}
                          onChange={this.handleChange}
                        />

                        <VideoELement
                          video="https://weprodigi.com/videos/quote-video/8.mp4"
                          poster="/images/quote-video/posters/8.jpg"
                          title="3D Character Animation"
                          name="video-type"
                          selected={this.state.formdata['video-type']}
                          onChange={this.handleChange}
                        />

                        <VideoELement
                          video="https://weprodigi.com/videos/quote-video/10.mp4"
                          poster="/images/quote-video/posters/10.jpg"
                          title="Screencast"
                          name="video-type"
                          selected={this.state.formdata['video-type']}
                          onChange={this.handleChange}
                        />

                        <VideoELement
                          video="https://weprodigi.com/videos/quote-video/11.mp4"
                          poster="/images/quote-video/posters/11.jpg"
                          title="Whiteboard"
                          name="video-type"
                          selected={this.state.formdata['video-type']}
                          onChange={this.handleChange}
                        />

                        <VideoELement
                          video="https://weprodigi.com/videos/quote-video/12.mp4"
                          poster="/images/quote-video/posters/12.jpg"
                          title="Typography"
                          name="video-type"
                          selected={this.state.formdata['video-type']}
                          onChange={this.handleChange}
                        />

                        <VideoELement
                          video="https://weprodigi.com/videos/quote-video/1.mp4"
                          poster="/images/quote-video/posters/1.jpg"
                          title="Shooting"
                          name="video-type"
                          selected={this.state.formdata['video-type']}
                          onChange={this.handleChange}
                        />

                        <VideoELement
                          video="https://weprodigi.com/videos/quote-video/9.mp4"
                          poster="/images/quote-video/posters/9.jpg"
                          title="Stock Videos & Images"
                          selected={this.state.formdata['video-type']}
                          name="video-type"
                          onChange={this.handleChange}
                        />

                        <VideoELement
                          image="https://weprodigi.com/images/quote-video/question.svg"
                          title="Other"
                          selected={this.state.formdata['video-type']}
                          name="video-type"
                          onChange={this.handleChange}
                        />
                      </Suspense>
                    )}
                  </div>
                  <p className={'text-16 flex items-center flex-wrap text-purple font-bolder mt-20'}>
                    {this.props.from == 'healthcare' ? 'How long do you believe your video should be?' : 'How long do you believe video should be?'}{' '}
                    {this.state.req && (videoLength.length == 0 || (videoLength.includes('Other') && !this.state.formdata['other'])) && (
                      <span
                        className="bg-green animate-warning text-white text-12 normal-case font-normal px-5 rounded-5"
                        style={{ verticalAlign: 'super' }}
                      >
                        This is required
                      </span>
                    )}{' '}
                  </p>
                  <div className={'flex justify-between'}>
                    <div className="form-group   w-half-auto">
                      <div className=" mt-10 mb-10">
                        <div
                          onClick={() => {
                            this.setVideoLength('video-length', '10 sec');
                          }}
                          className={`${
                            videoLength.includes('10 sec') ? 'border-1 border-green ' : 'border-0 '
                          } flex justify-between px-10 bg-white items-center h-36 rounded-5 cursor-pointer`}
                        >
                          <span className="text-16 leading-20">10 sec</span>
                          {videoLength.includes('10 sec') && <img src="https://weprodigi.com/uploads/files/shares/ppc/check.png" alt="check" />}
                        </div>
                      </div>
                      <div className=" mt-10 mb-10">
                        <div
                          onClick={() => {
                            this.setVideoLength('video-length', '15 sec');
                          }}
                          className={`${
                            videoLength.includes('15 sec') ? 'border-1 border-green ' : 'border-0 '
                          } flex justify-between px-10 bg-white items-center h-36 rounded-5 cursor-pointer`}
                        >
                          <span className="text-16 leading-20">15 sec</span>
                          {videoLength.includes('15 sec') && <img src="https://weprodigi.com/uploads/files/shares/ppc/check.png" alt="check" />}
                        </div>
                      </div>
                      <div className=" mt-10 mb-10">
                        <div
                          onClick={() => {
                            this.setVideoLength('video-length', '30 sec');
                          }}
                          className={`${
                            videoLength.includes('30 sec') ? 'border-1 border-green ' : 'border-0 '
                          } flex justify-between px-10 bg-white items-center h-36 rounded-5 cursor-pointer`}
                        >
                          <span className="text-16 leading-20">30 sec</span>
                          {videoLength.includes('30 sec') && <img src="https://weprodigi.com/uploads/files/shares/ppc/check.png" alt="check" />}
                        </div>
                      </div>
                      <div className=" mt-10 mb-10">
                        <div
                          onClick={() => {
                            this.setVideoLength('video-length', '45 sec');
                          }}
                          className={`${
                            videoLength.includes('45 sec') ? 'border-1 border-green ' : 'border-0 '
                          } flex justify-between px-10 bg-white items-center h-36 rounded-5 cursor-pointer`}
                        >
                          <span className="text-16 leading-20">45 sec</span>
                          {videoLength.includes('45 sec') && <img src="https://weprodigi.com/uploads/files/shares/ppc/check.png" alt="check" />}
                        </div>
                      </div>
                      <div className=" mt-10 mb-10">
                        <div
                          onClick={() => {
                            this.setVideoLength('video-length', '60 sec');
                          }}
                          className={`${
                            videoLength.includes('60 sec') ? 'border-1 border-green ' : 'border-0 '
                          } flex justify-between px-10 bg-white items-center h-36 rounded-5 cursor-pointer`}
                        >
                          <span className="text-16 leading-20">60 sec</span>
                          {videoLength.includes('60 sec') && <img src="https://weprodigi.com/uploads/files/shares/ppc/check.png" alt="check" />}
                        </div>
                      </div>
                    </div>
                    <div className="form-group mr-5  ml-10 w-half">
                      <div className="mt-10 mb-5">
                        <div
                          onClick={() => {
                            this.setVideoLength('video-length', '90 sec');
                          }}
                          className={`${
                            videoLength.includes('90 sec') ? 'border-1 border-green ' : 'border-0 '
                          } flex justify-between px-10 bg-white items-center h-36 rounded-5 cursor-pointer`}
                        >
                          <span className="text-16 leading-20">90 sec</span>
                          {videoLength.includes('90 sec') && <img src="https://weprodigi.com/uploads/files/shares/ppc/check.png" alt="check" />}
                        </div>
                      </div>
                      <div className=" mt-10 mb-5">
                        <div
                          onClick={() => {
                            this.setVideoLength('video-length', '120 sec');
                          }}
                          className={`${
                            videoLength.includes('120 sec') ? 'border-1 border-green ' : 'border-0 '
                          } flex justify-between px-10 bg-white items-center h-36 rounded-5 cursor-pointer`}
                        >
                          <span className="text-16 leading-20">120 sec</span>
                          {videoLength.includes('120 sec') && <img src="https://weprodigi.com/uploads/files/shares/ppc/check.png" alt="check" />}
                        </div>
                      </div>
                      <div className=" mt-10 mb-5">
                        <div
                          onClick={() => {
                            this.setVideoLength('video-length', '3 min');
                          }}
                          className={`${
                            videoLength.includes('3 min') ? 'border-1 border-green ' : 'border-0 '
                          } flex justify-between px-10 bg-white items-center h-36 rounded-5 cursor-pointer`}
                        >
                          <span className="text-16 leading-20">3 min</span>
                          {videoLength.includes('3 min') && <img src="https://weprodigi.com/uploads/files/shares/ppc/check.png" alt="check" />}
                        </div>
                      </div>
                      <div className=" mt-10 mb-10">
                        <div
                          onClick={() => {
                            this.setVideoLength('video-length', '5 min');
                          }}
                          className={`${
                            videoLength.includes('5 min') ? 'border-1 border-green ' : 'border-0 '
                          } flex justify-between px-10 bg-white items-center h-36 rounded-5 cursor-pointer`}
                        >
                          <span className="text-16 leading-20">5 min</span>
                          {videoLength.includes('5 min') && <img src="https://weprodigi.com/uploads/files/shares/ppc/check.png" alt="check" />}
                        </div>
                      </div>
                      <div className="mb-15">
                        <div
                          onClick={() => {
                            this.setVideoLength('smmPackages', 'Other');
                          }}
                          className={`${
                            videoLength.includes('Other') ? 'border-1 border-green ' : 'border-0 '
                          } flex justify-between px-10 bg-white items-center h-36 rounded-5 cursor-pointer`}
                        >
                          <span className="text-16 leading-20">Other</span>
                          {videoLength.includes('Other') && <img src="https://weprodigi.com/uploads/files/shares/ppc/check.png" alt="check" />}
                        </div>
                      </div>
                      <input
                        className={`pl-10 rounded-5 ${
                          videoLength.includes('Other') ? 'block' : 'hidden'
                        } w-full h-36 focus:placeholder-white   focus:outline-none py-2`}
                        type="text"
                        name={'other'}
                        id={'other'}
                        onChange={this.handleChange}
                        placeholder={'Preferred video duration'}
                      />
                    </div>
                  </div>
                </div>
                <div ref={this.stepTwo} className={`mt-20 ${step == 2 ? 'block' : 'hidden'}`}>
                  <p className={'text-16 flex items-center flex-wrap text-purple font-bolder mt-20'}>
                    Do you need us to write a script?{' '}
                    {this.state.req && script.length == 0 && (
                      <span
                        className="bg-green animate-warning text-white text-12 normal-case font-normal px-5 rounded-5"
                        style={{ verticalAlign: 'super' }}
                      >
                        This is required
                      </span>
                    )}{' '}
                  </p>
                  <div className={'flex justify-between'}>
                    <div className="form-group   w-half-auto">
                      <div className=" mt-10 mb-10">
                        <div
                          onClick={() => {
                            this.setScript('video-length', 'Yes');
                          }}
                          className={`${
                            script.includes('Yes') ? 'border-1 border-green ' : 'border-0 '
                          } flex justify-between px-10 bg-white items-center h-36 rounded-5 cursor-pointer`}
                        >
                          <span className="text-15 leading-20">Yes</span>
                          {script.includes('Yes') && <img src="https://weprodigi.com/uploads/files/shares/ppc/check.png" alt="check" />}
                        </div>
                      </div>
                      <div className=" mt-10 mb-10">
                        <div
                          onClick={() => {
                            this.setScript('video-length', 'My script needs some optimizations');
                          }}
                          className={`${
                            script.includes('My script needs some optimizations') ? 'border-1 border-green ' : 'border-0 '
                          } flex justify-between px-10 bg-white items-center h-36 rounded-5 cursor-pointer`}
                        >
                          <span className="text-15 leading-20">My script needs some optimizations</span>
                          {script.includes('My script needs some optimizations') && (
                            <img src="https://weprodigi.com/uploads/files/shares/ppc/check.png" alt="check" />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="form-group mr-5  ml-10 w-half">
                      <div className="mt-10 mb-5">
                        <div
                          onClick={() => {
                            this.setScript('video-length', 'No, I already have a script');
                          }}
                          className={`${
                            script.includes('9No, I already have a script') ? 'border-1 border-green ' : 'border-0 '
                          } flex justify-between px-10 bg-white items-center h-36 rounded-5 cursor-pointer`}
                        >
                          <span className="text-15 leading-20">No, I already have a script</span>
                          {script.includes('No, I already have a script') && (
                            <img src="https://weprodigi.com/uploads/files/shares/ppc/check.png" alt="check" />
                          )}
                        </div>
                      </div>
                      <div className=" mt-10 mb-5">
                        <div
                          onClick={() => {
                            this.setScript('video-length', 'No script required');
                          }}
                          className={`${
                            script.includes('No script required') ? 'border-1 border-green ' : 'border-0 '
                          } flex justify-between px-10 bg-white items-center h-36 rounded-5 cursor-pointer`}
                        >
                          <span className="text-15 leading-20">No script required</span>
                          {script.includes('No script required') && (
                            <img src="https://weprodigi.com/uploads/files/shares/ppc/check.png" alt="check" />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className={'text-16 text-purple font-bolder mt-20'}>Do you need a voiceover?</p>
                  <div className={'flex justify-between mt-15'}>
                    <div className="form-group   w-half">
                      <div className="radio-item radio-item-green">
                        <input type="radio" id="voiceover" name="voiceover" value="Yes" onChange={() => this.handleChange('voiceover', 'Yes')} />
                        <label htmlFor="voiceover">Yes</label>
                      </div>
                    </div>
                    <div className="form-group mr-5  ml-10 w-half">
                      <div className="radio-item radio-item-green">
                        <input type="radio" id="voiceover-no" name="voiceover" value="No" onChange={() => this.handleChange('voiceover', 'No')} />
                        <label htmlFor="voiceover-no">No</label>
                      </div>
                    </div>
                  </div>
                  <p className={'text-16 text-purple font-bolder mt-20'}>Do you have a budget in mind?</p>
                  <div className={'flex justify-between mt-15'}>
                    <div className="form-group   w-half">
                      <div className="radio-item radio-item-green">
                        <input type="radio" id="budget" name="budget" value="Yes" onChange={() => this.handleChange('budget', 'Yes')} />
                        <label htmlFor="budget">Yes</label>
                      </div>
                    </div>
                    <div className="form-group mr-5  ml-10 w-half">
                      <div className="radio-item radio-item-green">
                        <input type="radio" id="budget-no" name="budget" value="No" onChange={() => this.handleChange('budget', 'No')} />
                        <label htmlFor="budget-no">No</label>
                      </div>
                    </div>
                  </div>
                  <input
                    className={`pl-10 mt-10 rounded-5 ${
                      this.state.formdata.budget == 'Yes' ? 'block' : 'hidden'
                    } w-full h-36 focus:placeholder-white   focus:outline-none py-2`}
                    type="text"
                    name={'budget-amount'}
                    id={'budget-amount'}
                    onChange={this.handleChange}
                    placeholder={'Budget amount'}
                  />
                  <p className={'text-16 text-purple font-bolder mt-20'}>Information that you want to share with us</p>
                  <div className={'w-full mt-5'}>
                    <Textarea
                      type="text"
                      placeholder={'Main goals, deadlines & etc.'}
                      name="note"
                      bg="resize-none bg-white w-full focus:outline-none"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div ref={this.stepTree} className={`mt-20 ${step == 3 ? 'block' : 'hidden'}`}>
                  <p className={'text-16 text-purple flex items-center flex-wrap font-bolder'}>
                    Your Name *{' '}
                    {this.state.req && !this.state.formdata['name'] && (
                      <span
                        className="bg-green animate-warning text-white text-12 normal-case font-normal px-5 rounded-5"
                        style={{ verticalAlign: 'super' }}
                      >
                        This is required
                      </span>
                    )}
                  </p>
                  <div className={'w-full mt-5'}>
                    <Input
                      type="text"
                      name="name"
                      bg="bg-white w-full focus:outline-none quote-input-name"
                      required={true}
                      onChange={this.handleChange}
                    />
                  </div>

                  <p className={'text-16 text-purple flex items-center flex-wrap font-bolder mt-20'}>
                    Your Business Email *
                    {this.state.req && !this.state.formdata['email'] && (
                      <span
                        className="bg-green animate-warning text-white text-12 normal-case font-normal px-5 rounded-5"
                        style={{ verticalAlign: 'super' }}
                      >
                        This is required
                      </span>
                    )}
                    {this.state.req && this.state.formdata['email'] && !this.emailValidation(this.state.formdata['email']) && (
                      <span
                        className="bg-green animate-warning text-white text-12 normal-case font-normal px-5 rounded-5"
                        style={{ verticalAlign: 'super' }}
                      >
                        Email is not valid
                      </span>
                    )}
                  </p>
                  <div className={'w-full mt-5'}>
                    <Input
                      type="email"
                      name="email"
                      bg={
                        this.state.req && this.state.formdata['email'] && !this.isEmailBusiness(this.state.formdata['email'])
                          ? 'border border-red bg-red-200 w-full focus:outline-none quote-input-email'
                          : 'bg-white w-full focus:outline-none quote-input-email'
                      }
                      required={true}
                      onChange={this.handleChange}
                    />
                    {this.state.req && this.state.formdata['email'] && !this.isEmailBusiness(this.state.formdata['email']) && (
                      <p className="flex">
                        <span className="icon icon-info w-24 h-15 inline-block" />
                        <span className="text-red text-12 normal-case font-normal italic">Please enter your Business Email</span>
                      </p>
                    )}
                  </div>

                  <p className={'text-16 text-purple font-bolder mt-20'}>Your Phone Number </p>
                  <div className={'w-full mt-5'}>
                    <Input type="text" name="number" bg="bg-white w-full focus:outline-none" onChange={this.handleChange} />
                  </div>

                  <p className={'text-16 text-purple font-bolder mt-20'}>What’s the best way to contact you?</p>

                  <div className="flex flex-row mt-15">
                    <div className="form-group mr-30">
                      <div className="radio-item radio-item-green">
                        <input
                          type="radio"
                          id="contact-via-email-1-pricing"
                          name="contact-via"
                          value="Email"
                          onChange={() => this.handleChange('contact-via', 'Email')}
                        />
                        <label htmlFor="contact-via-email-1-pricing">Email</label>
                      </div>
                    </div>
                    <div className="form-group mr-30">
                      <div className="radio-item radio-item-green">
                        <input
                          type="radio"
                          id="contact-via-phone-1-pricing"
                          name="contact-via"
                          value="Phone"
                          onChange={() => this.handleChange('contact-via', 'Phone')}
                        />
                        <label htmlFor="contact-via-phone-1-pricing">Phone</label>
                      </div>
                    </div>
                    <div className="form-group mr-30">
                      <div className="radio-item radio-item-green">
                        <input
                          type="radio"
                          id="contact-via-both-1-pricing"
                          name="contact-via"
                          value="Both"
                          onChange={() => this.handleChange('contact-via', 'Both')}
                        />
                        <label htmlFor="contact-via-both-1-pricing">Both</label>
                      </div>
                    </div>
                  </div>

                  <div className={'flex justify-between mt-15'}>
                    <Checkbox
                      name="nda"
                      value="Yes"
                      checked={this.state.formdata.nda == 'Yes'}
                      onChange={param => this.handleChange(param, this.state.formdata.nda == 'Yes' ? 'No' : 'Yes')}
                    >
                      Send NDA (Non-Disclosure Agreement)
                    </Checkbox>
                  </div>
                </div>

                <div className={'w-full prev-next-part flex justify-between text-white mt-20'}>
                  <div>
                    {step == 2 && (
                      <button
                        className={'flex items-center  hover:bg-purple focus:bg-purple bg-green rounded-5 px-10 py-5 text-14'}
                        type={'button'}
                        onClick={e => {
                          this.setState({ step: 1 });
                          this.stepOne.current.scrollIntoView();
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={15}
                          height={15}
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
                        </svg>
                        Previous
                      </button>
                    )}
                    {step == 3 && (
                      <button
                        className={'flex hover:bg-purple items-center bg-green rounded-5 px-10 py-5 text-14'}
                        type={'button'}
                        onClick={e => {
                          this.setState({ step: 2 });
                          this.stepTwo.current.scrollIntoView();
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={15}
                          height={15}
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
                        </svg>
                        Previous
                      </button>
                    )}
                  </div>

                  <div>
                    {step == 1 && (
                      <button
                        className={'flex items-center hover:bg-purple focus:bg-purple bg-green rounded-5 px-10 py-5 text-14 quote-next-1'}
                        type={'button'}
                        onClick={e => {
                          let type = this.state.formdata['video-type'];

                          if (
                            videoLength.length == 0 ||
                            (videoLength.includes('Other') && !document.getElementById('other').value) ||
                            type == undefined
                          ) {
                            this.setState({ req: true });
                            this.stepOne.current.scrollIntoView();
                          } else {
                            this.setState({ step: 2 });
                            this.mainRef.current.scrollIntoView();
                            this.setState({ req: false });
                          }
                        }}
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={15}
                          height={15}
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                        </svg>
                      </button>
                    )}
                    {step == 2 && (
                      <button
                        className={'flex hover:bg-purple items-center bg-green rounded-5 px-10 py-5 text-14 quote-next-5'}
                        type={'button'}
                        onClick={e => {
                          this.setState({ step: 3 });
                          this.setState({ req: false });
                        }}
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={15}
                          height={15}
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                        </svg>
                      </button>
                    )}
                  </div>
                </div>
                <div className="loader-part flex w-full mt-20">
                  <div
                    className={`w-full border-t-2 ${
                      step == 1 || step == 2 || step == 3 ? 'border-green' : 'border-gray-slider'
                    } flex justify-center text-center relative`}
                  >
                    <button
                      className={`cursor-pointer w-20 h-20 flex justify-center items-center text-12 rounded-50p text-white  ${
                        step == 1 || step == 2 || step == 3 ? 'bg-green ' : '  bg-gray-slider'
                      } absolute -top-10`}
                    >
                      1
                    </button>
                  </div>
                  <div
                    className={`w-full border-t-2 ${
                      step == 2 || step == 3 ? 'border-green' : 'border-gray-slider'
                    } flex justify-center text-center relative`}
                  >
                    <button
                      className={`cursor-pointer w-20 h-20 flex justify-center items-center text-12 rounded-50p text-white  ${
                        step == 2 || step == 3 ? 'bg-green ' : '  bg-gray-slider'
                      } absolute -top-10`}
                    >
                      2
                    </button>
                  </div>
                  <div className={`w-full border-t-2 ${step == 3 ? 'border-green' : 'border-gray-slider'} flex justify-center text-center relative`}>
                    <button
                      className={`cursor-pointer w-20 h-20 flex justify-center items-center text-12 rounded-50p text-white  ${
                        step == 3 ? 'bg-green ' : '  bg-gray-slider'
                      } absolute -top-10`}
                    >
                      3
                    </button>
                  </div>
                </div>
                {step != 3 ? (
                  <div className={'mt-20 text-center text-12 text-black leading-15'}>*An email address is required in the form</div>
                ) : (
                  <div className={'mt-20 text-center'}>
                    <button
                      id={'offer-submit'}
                      className={
                        'text-white gap-2 mx-auto flex justify-center items-center text-16 hover:bg-green focus:bg-green bg-purple rounded-5 px-20 py-5 capitalize'
                      }
                      disabled={loading}
                      onClick={e => {
                        let name = this.state.formdata['name'];
                        let email = this.state.formdata['email'];
                        if (name && email && this.emailValidation(email) && this.isEmailBusiness(email)) {
                          e.preventDefault();
                          this.onSubmit(e);
                          this.setState({ req: false });
                        } else if (!this.isEmailBusiness(email)) {
                          e.preventDefault();
                          this.onSubmit(e);
                          this.setState({ req: true });
                        } else {
                          this.setState({ req: true });
                        }
                      }}
                      type="button"
                    >
                      {loading && (
                        <svg className="pl" viewBox="0 0 200 200" width="200" height="200" xmlns="http://www.w3.org/2000/svg">
                          <defs>
                            <linearGradient id="pl-grad1" x1="1" y1="0.5" x2="0" y2="0.5">
                              <stop offset="0%" stopColor="hsl(313,90%,55%)" />
                              <stop offset="100%" stopColor="hsl(223,90%,55%)" />
                            </linearGradient>
                            <linearGradient id="pl-grad2" x1="0" y1="0" x2="0" y2="1">
                              <stop offset="0%" stopColor="hsl(313,90%,55%)" />
                              <stop offset="100%" stopColor="hsl(223,90%,55%)" />
                            </linearGradient>
                          </defs>
                          <circle
                            className="pl__ring"
                            cx="100"
                            cy="100"
                            r="82"
                            fill="none"
                            stroke="url(#pl-grad1)"
                            strokeWidth="36"
                            strokeDasharray="0 257 1 257"
                            strokeDashoffset="0.01"
                            strokeLinecap="round"
                            transform="rotate(-90,100,100)"
                          />
                          <line
                            className="pl__ball"
                            stroke="url(#pl-grad2)"
                            x1="100"
                            y1="18"
                            x2="100.01"
                            y2="182"
                            strokeWidth="36"
                            strokeDasharray="1 165"
                            strokeLinecap="round"
                          />
                        </svg>
                      )}
                      submit
                    </button>

                    {this.state.req && this.state.formdata['email'] && !this.isEmailBusiness(this.state.formdata['email']) && (
                      <p className="flex">
                        <span className="icon icon-info-yellow w-24 h-15 inline-block" />
                        <span className="italic text-left text-12">
                          Due to the high volume of inquiries, we are currently prioritizing businesses with dedicated email addresses.
                        </span>
                      </p>
                    )}
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
        <Thanks show={this.state.showThanks} loaded={this.state.loadedThanks} hide={this.hideThanksModal} backUrl={'/'}>
          One of our account managers will contact you and confirm your request. If you don’t receive an email from us within 24 hours please check
          your Spam Box.
        </Thanks>
      </>
    );
  }
}

export default Pricing;
